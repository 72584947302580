import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../Menu/Menu';
import './AktivizimiLlogarise.scss';
import Logo from '../../images/LOGO_GREEN.png';

const AktivizimiLlogarise = () => {
  return (
    <>
      <div className="aktivizimi-llogarise">
        <div className="row">
          <div className="col-12 logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            <Menu />
            {/* <HeroSection /> */}
          </div>
          <div className="col-md-12 tekst_konfirmimi">
            {/* <p className="text_1">Llogaria juaj u aktivizua me sukses! </p>
            <p>Tani mund te kyqeni.</p> */}
            Llogaria juaj është në procesim...
          </div>
        </div>
      </div>
    </>
  );
};

export default AktivizimiLlogarise;
