import React from 'react';
import { Link } from 'react-router-dom';
import './furnitoret.scss';

const Furnitoret = (props) => {
  const { data } = props;

  console.log('furnitoret', data);
  return (
    <div className="furnitoret" id="furnitoret">
      <div className="container">
        <div className="row">
          <div className="col-md-12 title">
            <h1>Furnitorët</h1>
          </div>
          <div className="col-md-12  img_donatoret">
            {/* <Carousel responsive={responsive}> */}
            {data?.map((el, index) => {
              return (
                <Link to={el?.link}>
                  <img src={el?.image} />
                </Link>
              );
            })}
            {/* </Carousel> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Furnitoret;
