import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './RegjistroBiznesin.scss';
import jwt_decode from 'jwt-decode';
import Axios from 'axios';
import validator from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const Form = () => {
  const [agree, setAgree] = useState(false);
  const token = window.localStorage.getItem('cd');
  const decode = token ? jwt_decode(token) : null;

  const [userData, setuserData] = useState([]);
  // const [isUserEdit, setisUserEdit] = useState(false);
  // const [imageData, setimageData] = useState(null);
  // const [ndryshoImazhin, setndryshoImazhin] = useState(false);
  // const [isPasswordEdit, setisPasswordEdit] = useState(false);
  // const [passData, setpassData] = useState([]);
  // const [ageRange, setAgeRange] = useState([]);
  const [country, setCountry] = useState([]);
  const [city, setcity] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [regjistrimi, setRegjistrimi] = useState('');
  const [passValidation, setPasswordValidation] = useState('');
  const [emailError, setEmailError] = useState('');
  const [numriFiskal, setNumriFiskal] = useState('');
  const [recaptcha, setRecaptcha] = useState(false);

  const btnHandler = (e) => {
    e.preventDefault();
    ruajNdryshimet();
    // alert('Regjistrimi u krye me sukses!');
    console.log('data', userData);
  };

  function handleRecaptcha(value) {
    console.log('Captcha value:', value);
    setRecaptcha(true);
  }

  // user data biznes handler
  const fjalkalimi = (e) => {
    const ud = Object.assign({}, userData);
    ud.pass = e.target.value;
    // setuserData(ud);
    if (
      ud.pass.match(
        '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$'
      )
    ) {
      setPasswordValidation('');
      setuserData(ud);
    } else {
      setPasswordValidation(
        'Fjalëkalimi duhet të ketë minum 8 karaktere, një shkronjë të madhe dhe një të vogël, një karakter special dhe një numër.'
      );
    }
  };

  // const fjalkalimiKonfirmimi = (e) => {
  //     const ud = Object.assign({}, userData);
  //     ud.pass2 = e.target.value;
  //     setpassData(ud);
  // };

  const emri = (e) => {
    const ud = Object.assign({}, userData);
    ud.name = e.target.value;
    setuserData(ud);
  };

  const mbiemri = (e) => {
    const ud = Object.assign({}, userData);
    ud.surname = e.target.value;
    setuserData(ud);
  };

  const email = (e) => {
    const ud = Object.assign({}, userData);
    ud.email = e.target.value;
    setuserData(ud);
    if (validator.isEmail(ud.email)) {
      setEmailError('');
    } else {
      setEmailError('Shtypni email valide!');
    }
  };

  const telefoni = (e) => {
    // console.log('teleFOOOOni',e)
    const ud = Object.assign({}, userData);
    ud.phone = e.target.value;
    setuserData(ud);
  };

  // biznes data event handler
  const emriBiznesit = async (e) => {
    const ud = await Object.assign({}, userData);
    ud.storename = e.target.value;
    setuserData(ud);
  };

  const numbriBiznesit = async (e) => {
    const ud = await Object.assign({}, userData);
    ud.fiscal_nr = e.target.value;
    // const validateBN = '/^d{10}$/';
    setuserData(ud);
    if (ud.fiscal_nr.length === 9) {
      setNumriFiskal('');
    } else {
      setNumriFiskal('Numri unik indetifikues duhet të përmbaj 9 karaktere!');
    }
  };

  const shtetiBiznesit = async (e) => {
    const ud = await Object.assign({}, userData);
    ud.country_id = e.target.value;
    setuserData(ud);
  };
  const qytetiBiznesit = async (e) => {
    const ud = await Object.assign({}, userData);
    ud.city_id = e.target.value;
    setuserData(ud);
  };

  const kategoriaBiznesit = async (e) => {
    const ud = await Object.assign({}, userData);
    ud.store_cat = e.target.value;
    setuserData(ud);
  };
  const adresaBiznesit = async (e) => {
    const ud = await Object.assign({}, userData);
    ud.address = e.target.value;
    setuserData(ud);
  };

  const telefonBiznesi = (e) => {
    // console.log('teleFOOOOni',e)
    const ud = Object.assign({}, userData);
    ud.store_phone = e.target.value;
    setuserData(ud);
  };
  const biznesTextArea = async (e) => {
    const ud = await Object.assign({}, userData);
    ud.store_description = e.target.value;
    setuserData(ud);
  };

  const oraHapjes = (e) => {
    // console.log('teleFOOOOni',e)
    const ud = Object.assign({}, userData);
    ud.opening_hour = e.target.value;
    setuserData(ud);
  };
  const oraMbylljes = (e) => {
    // console.log('teleFOOOOni',e)
    const ud = Object.assign({}, userData);
    ud.closing_hour = e.target.value;
    setuserData(ud);
  };

  const checkboxHandler = async () => {
    setAgree(!agree);
    const ud = await Object.assign({}, userData);
    ud.terms_accept = true;
    setuserData(ud);
  };

  const getCountry = async () => {
    try {
      let country = await Axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1/general/countries/all`
      );
      console.log(country);
      setCountry(country?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCity = async () => {
    try {
      let city = await Axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1/general/cities/all`
      );
      setcity(city?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      let categories = await Axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1/general/store-categories/all`
      );
      setCategories(categories?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountry();
    getCategories();
    getCity();
  }, []);

  //
  // console.log(process.env.REACT_APP_BASEURL);
  const ruajNdryshimet = async () => {
    // console.log('para se me shku', userData)

    await Axios.post(
      `${process.env.REACT_APP_BASEURL}/api/v1/furnitor/register`,
      userData
    )
      .then((res) => {
        console.log(res.data);
        // setisUserEdit(false);
        // window.location = '/';
        // setRegjistrimi('Sukses');

        toast.success(
          'Aplikacioni juaj u dërgua për shqyrtim me sukses. Ju lutem kontrolloni postën tuaj elektronike.'
        );
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log(err);
        // setError(err);
        toast.error(
          'Diçka shkoi gabim! Aplikacioni juaj nuk u pranua, ju lutemi provoni përsëri!'
        );
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <div className="regjistrohu_form">
        {/* {regjistrimi === 'Sukses' && (
          <div className="row text-success text_confirmation ">
            Aplikacioni juaj u dërgua për shqyrtim me sukses. Ju lutem
            kontrolloni postën tuaj elektronike.
          </div>
        )} */}
        {/* {error != '' && (
          <div className="row text-danger text_confirmation">
            Shfrytezuesi ekziston! Ju lutem përdorni email adresë tjeter.
          </div>
        )} */}
        {error != '' && (
          <div className="row text-danger text_confirmation ">
            Ju lutem plotësoni të gjitha fushat!
          </div>
        )}

        <form onSubmit={ruajNdryshimet}>
          <div className="row w-100">
            <div className="col-md-6 col-sm-12">
              <p>
                <b>Te dhënat e përdoruesit:</b>
              </p>

              <label className="name_surname">
                <input
                  name="name"
                  onChange={emri}
                  type="text"
                  placeholder="Emri"
                  className="select_emri"
                />

                <input
                  name="surname"
                  type="text"
                  onChange={mbiemri}
                  placeholder="Mbiemri"
                  className="select_mbiemri"
                />
              </label>
              <label>
                <input
                  name="email"
                  type="email"
                  onChange={email}
                  placeholder="Email"
                  className="rb_select"
                  required
                />
                {error != '' && (
                  <div className="row text-danger text_confirmation3">
                    Shfrytëzuesi ekziston! Ju lutem përdorni email adresë
                    tjetër.
                  </div>
                )}
                <div className="row text-danger text_confirmation3">
                  {' '}
                  {emailError}
                </div>
              </label>
              <label>
                <input
                  name="phone"
                  type="phone"
                  onChange={telefoni}
                  placeholder="Numri Tel"
                  className="rb_select"
                />
              </label>
              <label>
                <input
                  name="pass"
                  type="password"
                  onChange={fjalkalimi}
                  placeholder="Fjalëkalimi"
                  className="rb_select"
                  // pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                />
                {
                  <div className="text-danger text_confirmation2">
                    {passValidation}
                  </div>
                }
              </label>
            </div>
            <div className="col-md-6  col-sm-12 form_right">
              <p>
                <b>Te dhënat e biznesit:</b>
              </p>

              <label>
                <input
                  name="storename"
                  type="text"
                  onChange={emriBiznesit}
                  placeholder=" Emri i Biznesit"
                  className="rb_select"
                />
              </label>
              <label>
                <input
                  name="fiscal_nr"
                  type="number"
                  onChange={numbriBiznesit}
                  placeholder=" Numri Unik Identifikues (NUI)"
                  className="rb_select"
                />
                <div className="row text-danger text_confirmation3">
                  {numriFiskal}
                </div>
              </label>

              <label>
                <select
                  name="country_id"
                  type="text"
                  onChange={shtetiBiznesit}
                  placeholder="Shteti"
                  className="rb_select"
                >
                  <option disabled selected hidden>
                    Shteti
                  </option>
                  {country?.map((el, i) => {
                    return (
                      <option value={el?._id} key={el?._id}>
                        {el?.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label>
                <select
                  name="country_id"
                  type="text"
                  onChange={qytetiBiznesit}
                  placeholder="Qyteti"
                  className="rb_select"
                >
                  <option disabled selected hidden>
                    Qyteti
                  </option>
                  {city?.map((el, i) => {
                    return (
                      <option value={el?._id} key={el?._id}>
                        {el?.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label>
                <select
                  className="rb_select"
                  name="store_cat"
                  onChange={kategoriaBiznesit}
                >
                  <option disabled selected hidden>
                    Kategoria e Biznesit
                  </option>
                  {categories?.map((el, i) => {
                    return (
                      <option value={el?._id} key={el?._id}>
                        {el?.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label>
                <input
                  name="address"
                  type="text"
                  onChange={adresaBiznesit}
                  placeholder=" Adresa"
                  className="rb_select"
                />
              </label>
              <label>
                <input
                  name="phone"
                  type="phone"
                  onChange={telefonBiznesi}
                  placeholder="Numri Tel"
                  className="rb_select"
                />
              </label>

              <label className="name_surname">
                <input
                  type="number"
                  id="tentacles"
                  name="tentacles"
                  min="1"
                  max="24"
                  placeholder="Ora e hapjes"
                  className="select_emri"
                  onChange={oraHapjes}
                />

                <input
                  type="number"
                  id="tentacles"
                  name="tentacles"
                  min="1"
                  max="24"
                  placeholder="Ora e mbylljes"
                  className="select_mbiemri"
                  onChange={oraMbylljes}
                />
              </label>
              <textarea
                onChange={biznesTextArea}
                rows={3}
                // cols={77}
                placeholder="   Përshkrimi"
              ></textarea>
            </div>

            <div className="col-md-12 terms_condition">
              <div className="check_">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={handleRecaptcha}
                />
                <input type="checkbox" id="agree" onChange={checkboxHandler} />
                <label htmlFor="agree" id="kushtet" className="kushtet">
                  Pajtohem me {/* <Link to="/termatdhekushtet"> */}
                  <a href="/termatdhekushtet" target="_blank">
                    <b> termat dhe kushtet </b>
                  </a>
                  {/* </Link> */}
                </label>
              </div>

              {/* <Link to="/shtobiznes"> */}

              <button
                // disabled={!agree}
                className="btn"
                onClick={btnHandler}
                disabled={recaptcha == false || agree == false ? true : false}
              >
                Continue
              </button>

              {/* </Link> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
