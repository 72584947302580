import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import 'font-awesome/css/font-awesome.min.css';
import renderHTML from 'react-render-html';
import Menu from '../Menu/Menu';
import AppStore from '../../images/Aapp-store-badge.png';
import GooglePlay from '../../images/Ggoogle-play-badge.png';
import Fb from '../../images/fb.png';
import Insta from '../../images/insta.png';
import Twitter from '../../images/twitter.png';

//CSS
import './HeroSection.scss';

const HeroSection = (props) => {
  const { data } = props;

  let imagePath = data?.banner_image;
  const content = data?.content;
  const moto = data?.moto;

  // const moto = data?.moto;

  console.log(data);

  return (
    <>
      <div className="hero_section">
        <div
          className="hero_image"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.55)), url("${imagePath}")`,
            // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 230, 0.4)), url("${imagePath}")`,
          }}
        >
          <div className="hero_content">
            <div className="row">
              <div className="col-md-12 menu_">
                <div className="menu">
                  <div className="icons">
                    <div className="facebook">
                      <a
                        // href={'https://facebook.com'}
                        href={data?.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook />
                        {/* <img src={Fb} alt="fb" /> */}
                      </a>
                    </div>

                    <div className="instagram">
                      <a
                        // href="https://instagram.com"
                        href={data?.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram />
                        {/* <img src={Insta} alt="insta" /> */}
                      </a>
                    </div>

                    <div className="twitter">
                      <a
                        // href="https://twitter.com"
                        href={data?.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                        {/* <img src={Twitter} alt="twitter" /> */}
                      </a>
                    </div>
                  </div>
                  <Menu />
                </div>
              </div>

              <div className="col-md-6">
                <div className="hero_text">
                  <Link to="/">
                    <div className="hero_logo"></div>
                  </Link>

                  <p>{content && renderHTML(content)}</p>

                  <div className="btn">
                    <a
                      className="btn_playstore"
                      href={data?.app_store}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={AppStore} alt="app store" />
                    </a>
                    <a
                      className="btn_appstore"
                      href={data?.play_store}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={GooglePlay} alt="google play" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="moto">
                  {/* <p>{moto && renderHTML(moto)}</p> */}
                  <h1>
                    {/* Redukto humbjet në Ushqim për një të ardhme më të gjelbër! */}
                    {moto?.moto_1}
                  </h1>
                  <p>
                    {/* Aplikacioni i parë dhe i vetëm në Kosovë dhe rajon që
                    lehtëson dhurimin e ushqimin dhe ka për qëllim reduktimin e
                    mbeturinave ushqimore. */}
                    {moto?.moto_2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
