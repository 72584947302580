import React, { useState, useEffect } from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import ToggleBar from '../components/ToggleBar/ToggleBar';
import OurStory from '../components/OurStory/OurStory';
import ContactForm from '../components/ContactForm/Contact';
import Faq from '../components/Faq/Faq';
import Footer from '../components/Footer/Footer';
import Statistics from '../components/Statistics/Statistics';
import LatestOffers from '../components/LatestOffers/LatestOffers';
import LazyLoad from 'react-lazyload';
import axios from '../axios';
import { css } from '@emotion/react';
import GridLoader from 'react-spinners/GridLoader';
import Blog from '../components/Blog/Blog';
import PromoVideo from '../components/PromoVideo/PromoVideo';
import Donatoret from '../components/Donatoret/Donatoret';
import Furnitoret from '../components/Furnitoret/furnitoret';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #red;
  position: absolute;
  top: 50%;
  left: 50%;
`;

const Home = () => {
  // const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState('#0d381f');

  useEffect(() => {
    axios.get('acf/v3/pages/30').then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  return loading === true ? (
    <GridLoader color={color} loading={loading} css={override} size={20} />
  ) : (
    <div className="wrapper">
      <LazyLoad>
        <HeroSection data={data?.acf?.banner} />
      </LazyLoad>
      {/* <LazyLoad> */}
      {/* <ToggleBar data={data?.acf?.partners} /> */}
      {/* <ToggleBar data={data?.acf?.partners2} /> */}
      {/* </LazyLoad> */}
      <LazyLoad>
        <PromoVideo url={data?.acf?.video} />
      </LazyLoad>
      <LazyLoad>
        <OurStory
          left={data?.acf?.our_story_left}
          right={data?.acf?.our_story_right}
        />
      </LazyLoad>
      <LazyLoad>
        <LatestOffers />
      </LazyLoad>
      <LazyLoad>
        <Statistics data={data?.acf?.charts} />
      </LazyLoad>
      <LazyLoad>
        <ContactForm data={data?.acf?.contact} />
      </LazyLoad>
      <LazyLoad>
        <Blog data={data?.acf?.banner} />
      </LazyLoad>
      <LazyLoad>
        <Faq data={data?.acf?.faqs} />
      </LazyLoad>
      <LazyLoad>
        <Donatoret />
      </LazyLoad>
      <LazyLoad>
        <Furnitoret data={data?.acf?.partners2?.partneret} />
      </LazyLoad>
      <Footer data={data?.acf?.footer} />
    </div>
  );
};

export default Home;
